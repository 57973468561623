import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='About' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='About Us' />
        <p>
          Economistry is a blog focused on conducting and communicating economic
          research with an emphasis on data visualization. Our mission is to
          advance the field of economics by sharing innovative tools for
          research and communication.
        </p>
        <p>
          To ease the transition from the ivory tower to the coffee shop, we
          will provide tutorials for those interested in doing the work of an
          economist, tools to help economists communicate their findings to a
          general audience, and visualizations designed to push the boundaries
          of sharing economic insights with non-economists.
        </p>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
